import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
    "inline-flex items-center gap-x-2 rounded-full justify-center whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
    {
        variants: {
            variant: {
                default: "bg-primary text-muted hover:!text-cyan-600",
                theme: "bg-cyan-600 text-white hover:bg-background hover:border hover:border-cyan-600 hover:text-cyan-600",
                "theme-outline": "border border-cyan-600 bg-background text-cyan-600 hover:bg-cyan-600 hover:text-white",
                destructive: "bg-destructive text-destructive-foreground hover:bg-cyan-600",
                "destructive-outline": "border border-destructive bg-background text-destructive hover:bg-destructive hover:text-destructive-foreground",
                outline: "border border-input bg-background hover:border-cyan-600 hover:bg-accent text-primary hover:text-cyan-600",
                secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
                ghost: "hover:bg-accent hover:text-cyan-600",
                link: "text-primary underline-offset-4 hover:underline",
            },
            size: {
                default: "h-10 px-4 py-2",
                xs: "h-7 px-3 text-xs",
                sm: "h-9 px-3",
                lg: "h-11 px-8",
                xl: "h-14 px-12",
                icon: "h-10 w-10 aspect-square !px-2",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "sm",
        },
    },
);


const Button = React.forwardRef(
    ({ className, variant, size, asChild = false, tip, tipPosition = "top", ...props }, ref) => {
        const Comp = asChild ? Slot : "button";
        if (!tip) {
            return (
                <Comp
                    className={cn(buttonVariants({ variant, size, className }))}
                    ref={ref}
                    {...props}
                />
            );
        }
        else {
            return (
                <Comp
                    data-tooltip-id="rmp-tooltip"
                    data-tooltip-place={tipPosition}
                    data-tooltip-html={tip}
                    className={cn(buttonVariants({ variant, size, className }))}
                    ref={ref}
                    {...props}
                />
            )
        }
    },
);
Button.displayName = "Button";

export { Button, buttonVariants };
